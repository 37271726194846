import { getRatio, type ResizeMode } from '@canalplus/mycanal-commons';
import type { IImageWallContent } from '@canalplus/mycanal-sdk';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { isValidElement, useMemo, type ImgHTMLAttributes } from 'react';
import { ImageWallContent } from '../ImageWallContent/ImageWallContent';
import type { RenderCardLinkerType } from '../type';
import styles from './ImageWallTv.module.css';

type ImageWallTvProps = {
  cardRender: RenderCardLinkerType;
  contents: IImageWallContent[];
  textElement: JSX.Element;
  resizeMode?: ResizeMode;
  trackingContext?: ApiV2Context;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

/**
 * The function format the contents in columns of 2 elements
 * @param firstElement Can be added if a text element is needed
 * @param contents are the contents to format
 * @param numberOfColumns can be changed by default it's 4
 * @returns contents like => [[content1, content2], [content3, content4]]
 */
export const formatContentsInColumns = (
  firstElement: JSX.Element | undefined,
  contents: IImageWallContent[],
  numberOfColumns = 4
): (JSX.Element | IImageWallContent)[][] => {
  const numberOfElements = firstElement
    ? numberOfColumns * 2 - 1
    : numberOfColumns * 2;
  const contentsToFormat = contents.slice(0, numberOfElements);
  const result: (JSX.Element | IImageWallContent)[][] = firstElement
    ? [[firstElement]]
    : [];
  contentsToFormat.forEach((content) => {
    const lastColumn = result[result.length - 1];
    if (lastColumn?.length === 2 || !lastColumn) {
      result.push([content]);
    } else {
      lastColumn.push(content);
    }
  });
  return result;
};

/**
 * This component is the adaptation of ImageWall with flexbox for compatibility
 * @param contents to show on the image wall
 * @param textElement is the possible text element to show in the first position
 * @param cardRender is a function used to display image panels
 */
function ImageWallTv({
  contents,
  textElement,
  trackingContext,
  loading,
  cardRender,
  resizeMode,
}: ImageWallTvProps): JSX.Element {
  const formattedContents = useMemo(
    () => formatContentsInColumns(textElement, contents),
    [contents, textElement]
  );

  return (
    <section className={classNames(styles.section, 'imageWallFlexBox')}>
      <ul className={classNames(styles.imageList)}>
        {formattedContents.map((contentsArr, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`key-${i}`} className={classNames(styles.imageColumn)}>
            {contentsArr.map((content, index) => {
              if (isValidElement(content)) {
                return (
                  <div className={styles.cardItem} key={content.key}>
                    {content}
                  </div>
                );
              }

              const {
                altImage,
                altLogoChannel,
                contentID,
                onClick,
                URLImage,
                imageRatio,
                URLLogoChannel,
                title,
              } = content as IImageWallContent;

              const ratio = getRatio({ imageRatio });
              const contentComponent = (
                <ImageWallContent
                  imageAlt={altImage}
                  imageUrl={URLImage}
                  logoAlt={altLogoChannel}
                  logoUrl={URLLogoChannel}
                  ratio={ratio}
                  title={title}
                  isTvDevice={true}
                  resizeMode={resizeMode}
                  loading={loading}
                />
              );

              return onClick ? (
                <div key={`${contentID}`} className={styles.cardItem}>
                  {cardRender(
                    onClick,
                    contentComponent,
                    `${contentID}_${index}`,
                    styles.ImageWall__grid__item__contentWrap__linker,
                    { ...trackingContext, context_list_position: i + 1 }
                  )}
                </div>
              ) : (
                <div
                  key={`${contentID}`}
                  tabIndex={0}
                  role="link"
                  className={classNames(styles.item__wrapper, styles.cardItem)}
                >
                  {contentComponent}
                </div>
              );
            })}
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ImageWallTv;
