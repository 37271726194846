import { DIMENSIONS, type ResizeMode } from '@canalplus/mycanal-commons';
import type {
  ApiV2Context,
  ApiV2OnClick,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { ImgHTMLAttributes, ReactNode } from 'react';
import { memo, useMemo } from 'react';
import { ImageType } from '../ImageType/ImageType';
import { Linker as FallbackLinker } from '../Linker/Linker';
import styles from './ProgramsHighlight.module.css';
import { ProgramsHighlightTv } from './ProgramsHighlightTv/ProgramsHighlightTv';
import type { ProgramsHighlightContent } from './types';

const defaultRenderContentImageLinker = (
  onClick: ApiV2OnClick,
  children: ReactNode,
  id: string,
  className?: string,
  trackingContext?: ApiV2Context
): ReactNode => (
  <FallbackLinker
    data={{ mainOnClick: { ...onClick, trackingContext } }}
    objKey="onClick"
    className={className}
    id={id}
  >
    {children}
  </FallbackLinker>
);

export type ProgramsHighlightProps = {
  isTvDevice: boolean;
  resizeMode?: ResizeMode;
  content?: ProgramsHighlightContent;
  trackingContext?: ApiV2Context;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
  Linker?:
    | React.ForwardRefExoticComponent<any>
    | ((props: any) => React.ReactElement);
  renderContentImageLinker?: typeof defaultRenderContentImageLinker;
};

function ProgramsHighlightComponent({
  content,
  isTvDevice,
  resizeMode,
  trackingContext,
  loading,
  Linker = FallbackLinker,
  renderContentImageLinker = defaultRenderContentImageLinker,
}: ProgramsHighlightProps): JSX.Element {
  const { button, description, diffusionLabel, title, onClick, URLImage } =
    content || {};
  const { onClick: mainOnClick } = button || {};

  const URLImageObject = useMemo(
    () => ({
      default: URLImage,
    }),
    [URLImage]
  );

  if (isTvDevice) {
    return (
      <ProgramsHighlightTv
        Linker={Linker}
        img={
          <ImageType
            dimensions={{ default: DIMENSIONS.PROGRAMS_HIGHLIGHT }}
            URLImage={URLImageObject}
            isArtDirection
            isTvDevice
            resizeMode={resizeMode}
            loading={loading}
          />
        }
        renderLinker={renderContentImageLinker}
        content={content}
        trackingContext={trackingContext}
      />
    );
  }

  const img = (
    <ImageType
      dimensions={DIMENSIONS.PROGRAMS_HIGHLIGHT}
      URLImage={URLImageObject}
      isArtDirection
      loading={loading}
    />
  );

  return (
    <div
      className={classNames(styles.ProgramsHighlight, 'programsHighlightFocus')}
    >
      {/* IMAGE */}
      <div className={styles.ProgramsHighlight__mediaWrap}>
        {onClick
          ? renderContentImageLinker(
              onClick,
              img,
              'highlight_mediaWrap',
              styles.ProgramsHighlight__mediaWrap__linker,
              trackingContext
            )
          : img}
      </div>
      {/* CTA */}
      <div className={styles.ProgramsHighlight__ctaWrap}>
        {diffusionLabel && (
          <h2 className={styles.ProgramsHighlight__diffusionLabel}>
            {diffusionLabel}
          </h2>
        )}
        {title && (
          <span className={styles.ProgramsHighlight__title}>{title}</span>
        )}
        {description && (
          <p className={styles.ProgramsHighlight__description}>{description}</p>
        )}
        {button && (
          <Linker
            className={styles.ProgramsHighlight__button}
            data={{
              mainOnClick: { ...mainOnClick, trackingContext },
            }}
            objKey="onClick"
          >
            {button.label}
          </Linker>
        )}
      </div>
    </div>
  );
}

export const ProgramsHighlight = memo(ProgramsHighlightComponent);
