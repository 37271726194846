import { useStore } from '@canalplus/one-navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { applicationResizeSelector } from '../../../store/slices/application-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import { areAnimationsAutoplaySelector } from '../../../store/slices/user-selectors';
import { IShowcaseState } from '../data/formater';
import Showcase from './Showcase';
import { ShowcaseContextProvider } from './ShowcaseContext';

export type ShowcaseContainerProps = {
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  url: string;
  isMainTemplate?: boolean;
};

function ShowcaseContainer({
  onClickParameters,
  onFocusable,
  url,
  isMainTemplate,
}: ShowcaseContainerProps): JSX.Element | null {
  const [{ isLoading, error, data }] = useQueryTemplate<IShowcaseState>(url, {
    onClickParameters,
    template: FetchRequestTypes.ShowcaseContainer,
    isMainTemplate,
  });

  // Reset the focus to default when data changes
  const store = useStore();
  useEffect(() => {
    if (onFocusable && data) {
      store.getActiveLayer().focus(undefined);
      onFocusable();
    }
  }, [onFocusable, store, data]);

  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const isTvDevice = useIsTvDevice();
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const isSmallScreenWidth: boolean = useSelector(isPhoneResolutionSelector);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) {
    return null;
  }
  const { strates } = data;

  return (
    <ShowcaseContextProvider>
      <MetaTagsTemplate data={data} enableAlternateLinksUpdate />
      <Showcase
        areAnimationsAutoplay={areAnimationsAutoplay}
        strates={strates}
        isTvDevice={isTvDevice}
        resizeMode={resizeMode}
        isSmallScreenWidth={isSmallScreenWidth}
      />
    </ShowcaseContextProvider>
  );
}

export default ShowcaseContainer;
