import classNames from 'classnames';
import type { DiLinkProps } from './DiLink.types';

export const LINK_TARGET_BLANK_REL = 'noreferrer';

/**
 * Dive Internal Link renders an unstyled anchor element.
 * It supports active, and disabled states for better user interaction.
 *
 * @example
 * <DiLink href="https://example.com">Visit Example</DiLink>
 */
export function DiLink({
  id,
  className,
  children,
  href,
  disabled,
  'data-testid': dataTestId,
  ...props
}: DiLinkProps): JSX.Element {
  return (
    <a
      data-testid={dataTestId}
      id={id}
      aria-label={typeof children === 'string' ? children : undefined}
      className={classNames({ 'cursor-not-allowed': disabled }, className)}
      {...props}
      {...(disabled
        ? { 'aria-disabled': 'true', onClick: undefined }
        : {
            href,
            rel: props.target === '_blank' ? LINK_TARGET_BLANK_REL : undefined,
          })}
    >
      {children}
    </a>
  );
}
