import { DIMENSIONS, type ResizeMode } from '@canalplus/mycanal-commons';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { memo, type ImgHTMLAttributes } from 'react';
import { ImageType } from '../ImageType/ImageType';
import { Linker as FallbackLinker } from '../Linker/Linker';
import styles from './Tiles.module.css';

type TileButton = {
  style: 'primary' | 'secondary';
  type: 'plain' | 'inline';
  onClick: ApiV2OnClick;
  label: string;
};

export type TilesContent = {
  buttons?: TileButton[];
  description: string;
  title: string;
  URLImage?: string;
};

export type TilesProps = {
  contents?: TilesContent[];
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  Linker?:
    | React.ForwardRefExoticComponent<any>
    | ((props: any) => React.ReactElement);
  title?: string;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

function TilesComponent({
  contents = [],
  isTvDevice = false,
  resizeMode,
  Linker = FallbackLinker,
  title,
  loading,
}: TilesProps): JSX.Element {
  return (
    <div className={classNames(styles.Tiles, 'tilesFocus')}>
      {title && <span className={styles.Tiles__title}>{title}</span>}

      {!!contents.length && (
        <ul className={styles.Tiles__tilesWrap} data-testid="ul-tiles-contents">
          {contents.map(
            ({ title: tileTitle, description, URLImage, buttons }) => (
              <li
                className={styles.Tiles__tile}
                data-tv-focusable
                key={`${tileTitle}-${description}`}
              >
                {URLImage && (
                  <ImageType
                    className={styles.Tiles__tile__icon}
                    dimensions={DIMENSIONS.TILES_ICON}
                    URLImage={{
                      default: URLImage,
                    }}
                    isTvDevice={isTvDevice}
                    resizeMode={resizeMode}
                    loading={loading}
                  />
                )}
                <span className={styles.Tiles__tile__title}>{tileTitle}</span>
                <p className={styles.Tiles__tile__description}>{description}</p>

                {buttons &&
                  buttons.map((button) => {
                    const buttonKey = `${button.onClick.displayName}_${button.onClick.path}`;

                    return (
                      <Linker
                        data={{ mainOnClick: button.onClick }}
                        key={buttonKey}
                        className={styles.Tiles__tile__link}
                      >
                        {button.label}
                      </Linker>
                    );
                  })}
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
}

export const Tiles = memo(TilesComponent);
