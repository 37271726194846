import type { DeviceToImageUrl } from '@canalplus/dive';
import type { ResizeMode } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { ImgHTMLAttributes } from 'react';
import { memo, useReducer } from 'react';
import { ImageType } from '../ImageType/ImageType';
import type { ImageTypeDimensions } from '../ImageType/types';
import { VideoBackground } from '../VideoBackground/VideoBackground';
import styles from './Poster.module.css';

type PosterTheme = {
  children?: string;
  cover?: string;
  hidden?: string;
  poster?: string;
  showcase?: string;
};

export type PosterProps = {
  children?: React.ReactNode;
  altImage?: string;
  borderRadius?: boolean;
  dimensions: ImageTypeDimensions;
  disabledStyle?: { disabled: boolean; opacityOnPoster: boolean };
  displayName?: string;
  image: DeviceToImageUrl;
  imageQualityPercentage?: number;
  isArtDirection?: boolean;
  isBackgroundTransparent?: boolean;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
  maxImageRatio?: number;
  theme?: PosterTheme;
  video?: string;
};

/**
 * Poster
 * --------------------
 *
 * Used to render an image or a video
 *
 * @param dimensions               Single object or composed object with image dimensions for one or all formats
 * @param image                    Object with default URL (required) and mobile URLs (optional)
 * @param altImage                 `alt` image attribute
 * @param borderRadius             Displays poster with border radius
 * @param disabled                 Whether the component is disabled or not
 * @param displayName              Secondary `alt` image attribute (if needed)
 * @param imageQualityPercentage   Image quality in percentage
 * @param isBackgroundTransparent  Whether background is transparent or not (no placeholder)
 * @param loading                  Image loading strategy
 * @param maxImageRatio            Maximum image pixel ratio (1, 1.3, 2)
 * @param theme                    Object with themes `className`
 * @param video                    Video `url` attribute
 * @param isArtDireciton           Enables responsive images using `<picture>` and viewport specific `<source>`
 */
function PosterComponent({
  altImage = 'poster',
  borderRadius,
  children,
  dimensions,
  disabledStyle = { disabled: false, opacityOnPoster: false },
  displayName,
  image,
  imageQualityPercentage,
  isBackgroundTransparent = false,
  isArtDirection = false,
  isDesktopOnly,
  isMobileOnly,
  isTvDevice = false,
  resizeMode,
  loading = 'lazy',
  maxImageRatio,
  theme = {},
  video,
}: PosterProps): JSX.Element {
  const [isImgLoaded, setIsImageLoaded] = useReducer(() => true, false);
  const { disabled, opacityOnPoster } = disabledStyle;

  return (
    <div
      className={classNames(styles.poster, theme.poster, theme.showcase, {
        [styles['poster--borderRadius']]: borderRadius,
        [styles['poster--isBackgroundTransparent']]: isBackgroundTransparent,
        [styles['poster--loader']]: !isTvDevice && !isImgLoaded,
        [styles['poster--disabled']]: disabled,
        [styles['poster--opacity']]: opacityOnPoster,
      })}
      data-testid="poster"
    >
      <ImageType
        loading={loading}
        className={classNames(
          'cover',
          styles.poster__cover,
          { [styles['poster__cover--borderRadius']]: borderRadius },
          theme.cover,
          theme.hidden
        )}
        dimensions={dimensions}
        URLImage={image}
        altImage={altImage || displayName}
        imageQualityPercentage={imageQualityPercentage}
        onLoad={!isTvDevice ? setIsImageLoaded : undefined}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        isTvDevice={isTvDevice}
        isArtDirection={isArtDirection}
        maxImageRatio={maxImageRatio}
        resizeMode={resizeMode}
      />

      {video && <VideoBackground videoUrl={video} showVideoDelay={1000} />}
      {children && (
        <div className={classNames(styles.poster__children, theme.children)}>
          {children}
        </div>
      )}
    </div>
  );
}

export const Poster = memo(PosterComponent);
